import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import { memo, useMemo } from "react";

import useLanguage from "@hooks/useLanguage";
import { classnames, mergeClasses } from "@lib/tools/helpers";
import st from "@styles/components/SlotsList.module.scss";

import { WorldLockIcon } from "@assets/icons/general/WorldLockIcon";

const PlayIcon = dynamic(() => import("@assets/icons/general/PlayIcon"));

// jsDoc
//This component needs refactoring to be more easily maintained
/**
 * @typedef {Object} SlotLinkProps
 * @property {Object} item
 * @property {boolean} inHouse
 * @property {boolean} recent
 * @property {boolean} isLive
 */
function SlotLink(props) {
  const { lastSlotStyle, index, translateLeft } = props;
  const item = props;
  const L = useLanguage("Slots");

  const edgeFirst = lastSlotStyle && index === 0;
  const lastStyle = edgeFirst
    ? st["last-slot-slider--first"]
    : lastSlotStyle
      ? st["last-slot-slider"]
      : "";

  const type = item.type || "originals";
  const url = item.url;

  const isAnyLive = type !== "slots" && type !== "originals";
  const isLive = type === "live";
  const inHouse = type === "originals";

  const href = useMemo(
    () => ({
      pathname: isAnyLive
        ? isLive
          ? `/casino/live/${url}`
          : `/casino/live/${type}/${url}`
        : `/casino/${type}/${url}`,
    }),
    [isAnyLive, isLive, type, url]
  );

  const hasCustomBanner = item?.custom_banner;

  const src = hasCustomBanner ?? item?.icon;

  const link = (
    <Link
      data-slot-link-id={item.id}
      prefetch={false}
      href={href}
      {...mergeClasses(
        lastStyle,
        classnames(
          st,
          item.region_blocked
            ? "quick-link-content-blocked"
            : "quick-link-content",
          translateLeft && "translate-left"
        )
      )}
    >
      <div className={`${st["slot-image"]} flex flex-col justify-center`}>
        {!item.region_blocked && <PlayIcon />}
      </div>

      {src && (
        <div className={st["slot-image-container"]}>
          <Image alt={item.name} src={src} fill unoptimized sizes="200px" />
          {!hasCustomBanner && !inHouse && (
            <div className={st["slot-image-overlay"]}>
              <span>{item.producer}</span>
            </div>
          )}
        </div>
      )}

      {!inHouse
        ? item.region_blocked && (
            <div className={st["game-details-display"]}>
              <div
                className={`${st["region-blocked-overlay"]} flex flex-col items-center`}
              >
                <WorldLockIcon />
                <h3 className={st["region-blocked-text"]}>
                  {L("not_available_region")}
                </h3>
              </div>
            </div>
          )
        : null}
    </Link>
  );

  return link;
}

export default memo(SlotLink);
